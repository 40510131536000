import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import "../styles/header.scss";
import { isAuthenticated, logout } from "../services/authService";

function Header({ socials }) {
  const topbarRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isNavBar, seIstNavBar] = useState(false);
  const [isNavBarClicked, seIstNavBarCliked] = useState(false);
  const [auth] = useState(() => isAuthenticated());
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
  };

  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-US", options);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = async () => {
    const result = await logout();
    if (result.success) {
      window.location.href = "/login";
    } else {
      console.error(result.error);
    }
  };

  return (
    <>
      <div
        className={`container-fluid sticky-header px-0 ${
          isScrolled ? "sticky-active" : ""
        }`}
      >
        <div
          ref={topbarRef}
          className={`container-fluid topbar bg-secondary ${
            isScrolled ? "hidden" : ""
          }`}
        >
          <div className="container px-0">
            <div className="topbar-top d-flex justify-content-between flex-lg-wrap">
              <div className="top-info flex-grow-0">
                <span className="rounded-circle btn-sm-square bg-primary me-2">
                  <i className="fas fa-tv text-white"></i>
                </span>
                <div className="pe-2 me-3 border-end border-white d-flex align-items-center">
                  <p className="mb-0 text-white fs-6 fw-normal">LIVE TV</p>
                </div>
                {/* <div className="overflow-hidden" style={{ width: "735px" }}>
                  <div id="note" className="ps-2">
                    <img
                      src={process.env.REACT_APP_ASSET_URL + "assets/img/degree.png"}
                      className="img-fluid rounded-0 -circle border border-3 border-primary me-2"
                      style={{ width: "30px", height: "30px" }}
                      alt=""
                    />
                    <a href={true}>
                      <p className="text-white mb-0 link-hover">
                        'മാധ്യമങ്ങളുണ്ടാകണം'; മാധ്യമ പ്രവര്‍ത്തകര്‍ക്കെതിരായ
                        കേസ് റദ്ദാക്കി ഹൈക്കോടതി
                      </p>
                    </a>
                  </div>
                </div> */}
              </div>
              <div className="top-link flex-lg-wrap">
                <i className="fas fa-calendar-alt text-white border-end border-secondary pe-2 me-2">
                  {" "}
                  <span className="text-light" style={{ fontSize: "0.6rem" }}>
                    {formattedDate}
                  </span>
                </i>
                <div className="d-flex icon">
                  {socials &&
                    socials.map((social) => (
                      <a
                        className="me-2"
                        href={social?.media_url}
                        target="_new"
                      >
                        <img
                          src={social?.icon?.url}
                          alt=""
                          style={{ border: "1px solid", borderRadius: "20px" }}
                        />
                      </a>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-primary">
          <div className="container px-0">
            <nav className="navbar navbar-light navbar-expand-xl">
              <Link to="/" className="logo navbar-brand mt-3">
                <img
                  src={process.env.REACT_APP_ASSET_URL + "assets/img/logo.png"}
                  alt="logo"
                  width="250"
                  className="d-inline-block align-top"
                />
              </Link>
              <button
                className="navbar-toggler py-2 px-3"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
                onClick={() => {
                  seIstNavBarCliked(true);
                  seIstNavBar(!isNavBar);
                  setInterval(() => {
                    seIstNavBarCliked(false);
                  }, 100);
                }}
              >
                <span className="fa fa-bars text-white"></span>
              </button>
              <div
                className={`collapse navbar-collapse bg-primary py-3 ${
                  isNavBar ? "show" : ""
                } ${isNavBarClicked ? "collapsing" : ""}`}
                id="navbarCollapse"
              >
                <Nav className="navbar-nav mx-auto border-top">
                  <Nav.Link
                    as={Link}
                    to="/"
                    className="nav-item nav-link text-white active"
                  >
                    HOME
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/latest-news"
                    className="nav-item nav-link text-white active"
                  >
                    LATEST NEWS
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/news-room"
                    className="nav-item nav-link text-white active"
                  >
                    NEWS ROOM
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/prime-news"
                    className="nav-item nav-link text-white active"
                  >
                    PRIME
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/entertainment"
                    className="nav-item nav-link text-white active"
                  >
                    ENTERTAINMENT
                  </Nav.Link>
                  {!auth && (
                    <Nav.Link
                      as={Link}
                      to="/login"
                      className="nav-item nav-link text-white active"
                    >
                      LOGIN
                    </Nav.Link>
                  )}
                  {auth && (
                    <Nav.Link
                      to="/logout"
                      onClick={(e) => {
                        e.preventDefault();
                        handleLogout();
                      }}
                      className="nav-item nav-link text-white active"
                    >
                      LOGOUT
                    </Nav.Link>
                  )}
                </Nav>
                <div className="d-flex flex-nowrap border-top pt-3 pt-xl-0">
                  <button
                    className="btn-search btn border border-primary btn-md-square rounded-circle bg-white my-auto"
                    data-bs-toggle="modal"
                    data-bs-target="#searchModal"
                  >
                    <i className="fas fa-search text-primary"></i>
                  </button>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
