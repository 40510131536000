import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useNavigate } from "react-router-dom";

function Special({ data }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="container-fluid latest-news py-1">
        <div className="container py-1">
          <h2 className="mb-4">SPECIAL</h2>
          <div className="latest-news-carousel owl-carousel">
            <Swiper
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              slidesPerView={2}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            >
              {data &&
                data.map((article) => (
                  <SwiperSlide>
                    <div className="latest-news-item">
                      <div
                        className="bg-light rounded"
                        onClick={() => {
                          navigate(`/news/${article?.slug}`);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="rounded-top overflow-hidden">
                          <img
                            src={article?.image?.url}
                            className="img-zoomin img-fluid rounded-0 -top w-100"
                            alt=""
                          />
                        </div>
                        <div className="d-flex flex-column p-4">
                          <a href={true} className="h4 ml">
                            {article?.title}
                          </a>
                          <div className="d-flex justify-content-between">
                            <a
                              href={true}
                              className="small text-body link-hover"
                            >
                              {article?.user?.name}
                            </a>
                            <small className="text-body d-block">
                              <i className="fas fa-calendar-alt me-1"></i>{" "}
                              {article?.published_date}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default Special;
