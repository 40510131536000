import React from "react";
const NotFound = () => {
  return (
    <>
      <div className="text-center mt-5 mb-5">
        <div className="row">
          <div
            className="col-md-8 border border-danger rounded-2 m-auto"
            style={{ background: "#ffc7c7", margin: "auto", padding: "5rem" }}
          >
            <h1 className="text-danger">Error 404!</h1>
            <h4 className="text-danger">
              The page you requested does not exist or has moved.!!
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
