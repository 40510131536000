import React, { useEffect } from "react";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container-fluid populer-news mt-3 mb-5">
      <div className="container py-3">
        <div className="row">
          <h1>എഡിറ്റോറിയൽ ബോർഡ്‌</h1>
          <div className="col-md-12 col-sm-12 mt-5">
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div class="card">
                  <img
                    class="card-img-top"
                    src={process.env.PUBLIC_URL + "assets/img/board/ce.jpg"}
                    alt=""
                  />
                  <div class="card-body text-center">
                    <h5 class="card-title">എൻ എ മുഹമ്മദ്‌ കുട്ടി</h5>
                    <p>ചീഫ് എഡിറ്റർ </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div class="card">
                  <img
                    class="card-img-top"
                    src={
                      process.env.PUBLIC_URL +
                      "assets/img/board/placeholder.jpg"
                    }
                    alt=""
                  />
                  <div class="card-body text-center">
                    <h5 class="card-title">ബിവിൻ പീറ്റർ</h5>
                    <p>എഡിറ്റർ </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div class="card">
                  <img
                    class="card-img-top"
                    src={
                      process.env.PUBLIC_URL +
                      "assets/img/board/placeholder.jpg"
                    }
                    alt=""
                  />
                  <div class="card-body text-center">
                    <h5 class="card-title">പി ജെ ആന്റണി</h5>
                    <p>ചെയർമാൻ </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <h1>ബോർഡ് അംഗങ്ങൾ</h1>
          <div className="col-md-12 col-sm-12 mt-5">
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div class="card">
                  <img
                    class="card-img-top"
                    src={
                      process.env.PUBLIC_URL +
                      "assets/img/board/placeholder.jpg"
                    }
                    alt=""
                  />
                  <div class="card-body d-flex justify-content-start">
                    <h5 class="card-title">ആർ കെ മുഹമ്മദ്‌</h5>
                    <p style={{ width: "100%" }}>(കേരള നാദം മുൻ മാനേജർ)</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div class="card">
                  <img
                    class="card-img-top"
                    src={
                      process.env.PUBLIC_URL +
                      "assets/img/board/placeholder.jpg"
                    }
                    alt=""
                  />
                  <div class="card-body text-center">
                    <h5 class="card-title">പി എ അലക്സാണ്ടർ </h5>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div class="card">
                  <img
                    class="card-img-top"
                    src={
                      process.env.PUBLIC_URL +
                      "assets/img/board/placeholder.jpg"
                    }
                    alt=""
                  />
                  <div class="card-body text-center">
                    <h5 class="card-title">ജോസി തുമ്പാനത്ത്</h5>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
