import React, { useEffect } from "react";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container-fluid populer-news mt-3 mb-5">
      <div className="container py-3">
        <div className="row">
          <div className="col-md-5 col-sm-12">
            <div className="m-auto">
              <div className="card p-2 bg-primary">
                <div className="card-header">
                  <h3>Contact Us</h3>
                </div>
                <div className="card-body bg-white">
                  <form>
                    <div className="input-group form-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text p-3">
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <div className="input-group form-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text p-3">
                          <i className="fas fa-envelope"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <div className="input-group form-group mb-3">
                      <textarea
                        className="form-control"
                        placeholder="Message"
                        rows={10}
                      ></textarea>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-sm-12">
            <h4>Address</h4>
            <div>Ernakulam</div>
            <div>Phone : 1234567890</div>
            <div>Email : info@keralanadham.com</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
