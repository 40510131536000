// StoryLayout.js
import React from "react";

function StoryLayout({ children, background }) {
  const backgroundStyle = {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    filter: "blur(50px)",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <div style={backgroundStyle}></div>
      <main className="d-flex justify-content-center bg-dark">{children}</main>
    </div>
  );
}

export default StoryLayout;
