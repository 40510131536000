import axios from "./axiosConfig";

const API_URL = "/api/home_settings";

export const getWebSettings = async (params = {}) => {
  try {
    const response = await axios.get(`${API_URL}`, {
      params,
      headers: {},
    });
    if (response.data) {
      return response.data;
    }
    return false;
  } catch (error) {
    console.error("Error fetching home page data:", error);
    throw error;
  }
};
