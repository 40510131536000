import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Category from "./pages/Category";
import Login from "./pages/Login";
import Register from "./pages/Register";
import MainLayout from "./layouts/MainLayout";
import StoriesComponent from "./pages/Stories";
import NewsView from "./pages/NewsView";
import Latest from "./pages/Latest";
import NewsRoom from "./pages/NewsRoom";
import Prime from "./pages/Prime";
import Entertainment from "./pages/Entertainment";
import Pivacy from "./pages/Privacy";
import NotFound from "./pages/NotFound";
import Epaper from "./pages/Epaper";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            <MainLayout>
              <Login />
            </MainLayout>
          }
        />
        <Route
          path="/register"
          element={
            <MainLayout>
              <Register />
            </MainLayout>
          }
        />
        <Route
          path="/about"
          element={
            <MainLayout>
              <About />
            </MainLayout>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <MainLayout>
              <Pivacy />
            </MainLayout>
          }
        />
        <Route
          path="/contact"
          element={
            <MainLayout>
              <Contact />
            </MainLayout>
          }
        />
        <Route
          path="/category/:slug"
          element={
            <MainLayout>
              <Category />
            </MainLayout>
          }
        />
        <Route
          path="/entertainment"
          element={
            <MainLayout>
              <Entertainment />
            </MainLayout>
          }
        />
        <Route
          path="/prime-news"
          element={
            <MainLayout>
              <Prime />
            </MainLayout>
          }
        />
        <Route
          path="/news-room"
          element={
            <MainLayout>
              <NewsRoom />
            </MainLayout>
          }
        />
        <Route
          path="/latest-news"
          element={
            <MainLayout>
              <Latest />
            </MainLayout>
          }
        />
        <Route path="/stories" element={<StoriesComponent />} />
        <Route
          path="/news/:slug"
          element={
            <MainLayout>
              <NewsView />
            </MainLayout>
          }
        />
        <Route
          path="/epaper"
          element={
            <MainLayout>
              <Epaper />
            </MainLayout>
          }
        />
        <Route
          path="/"
          element={
            <MainLayout>
              <Home />
            </MainLayout>
          }
        />
        <Route
          path="*"
          element={
            <MainLayout>
              <NotFound />
            </MainLayout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
