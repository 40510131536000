import axios from "./axiosConfig";

const API_URL = "/api/";

export const getHomeData = async (params = {}) => {
  try {
    const response = await axios.get(`${API_URL}home-data`, {
      params,
      headers: {},
    });
    if (response.data) {
      return response.data;
    }
    return false;
  } catch (error) {
    console.error("Error fetching home page data:", error);
    throw error;
  }
};

export const getNewsRoom = async (params = {}) => {
  try {
    const response = await axios.get(`${API_URL}news-room`, {
      params,
      headers: {},
    });
    if (response.data) {
      return response.data;
    }
    return false;
  } catch (error) {
    console.error("Error fetching news room data:", error);
    throw error;
  }
};

export const getAll = async (params = {}, token) => {
  try {
    const response = await axios.get(`${API_URL}list-articles`, {
      params,
      headers: {},
    });
    if (response.data) {
      return response.data;
    }
    return [];
  } catch (error) {
    console.error("Error fetching articles:", error);
    throw error;
  }
};

export const update = async (articleId, payload) => {
  try {
    const response = await axios.put(`${API_URL}${articleId}`, payload);
    if (response.data) {
      return response.data;
    }
    return false;
  } catch (error) {
    console.error("Authentication error:", error);
    throw error;
  }
};

export const deleteData = async (articleId, token) => {
  try {
    const response = await axios.delete(`${API_URL}${articleId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting article:", error);
    throw error;
  }
};

export const getData = async (articleId, token) => {
  try {
    const response = await axios.get(`${API_URL}${articleId}`, {
      headers: {},
    });
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

export const getArticleBySlug = async (slug, token) => {
  try {
    const response = await axios.get(`${API_URL}article-by-slug/${slug}`, {
      headers: {},
    });
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};
