import React from "react";
import { useNavigate } from "react-router-dom";

function Featured({ data }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="container-fluid features mb-0">
        <div className="container py-5">
          <div className="row g-4">
            {data &&
              data.map((article) => (
                <div className="col-md-6 col-lg-6 col-xl-3">
                  <div
                    className="row g-4 align-items-center features-item"
                    onClick={() => {
                      navigate(`/news/${article?.slug}`);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="col-4">
                      <div className="rounded-circle position-relative">
                        <div className="overflow-hidden rounded-0-circle">
                          <img
                            src={article?.image?.url}
                            className="img-zoomin img-fluid rounded-0 -circle w-100"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="features-content d-flex flex-column">
                        <p className="text-uppercase mb-2">
                          {article?.category?.name}
                        </p>
                        <a href={true} className="h6 ml">
                          {article?.title}
                        </a>
                        <small className="text-body d-block">
                          <i className="fas fa-calendar-alt me-1"></i>{" "}
                          {article?.published_date}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Featured;
