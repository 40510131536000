import React from "react";
import HtmlContent from "../HtmlContent";
import { useNavigate } from "react-router-dom";

function SpecialMain({ data }) {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="container-fluid py-1 my-1 mb-0"
        style={{ background: "#567280" }}
      >
        <div className="container">
          <div
            className="row g-4 align-items-center"
            onClick={() => {
              navigate(`/news/${data?.slug}`);
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="col-lg-7">
              <h1 className="mb-4 text-warning">SPECIAL</h1>
              <h1 className="mb-4 text-light ml">{data?.title}</h1>
              <p className="text-light mb-4 pb-2">
                <HtmlContent data={data?.content} />
              </p>
            </div>
            <div className="col-lg-5">
              <div className="rounded">
                <img
                  src={data?.image.url}
                  className="img-fluid rounded-0  w-100 rounded"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpecialMain;
