import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { getAll } from "../services/categories";
import { getWebSettings } from "../services/settings";

function MainLayout({ children }) {
  const [loading, setLoading] = useState(false);
  const [categories, setHomeCategories] = useState([]);
  const [webSettings, setWebSettings] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchHomeData();
    fetchHomeSettings();
  }, []);

  const fetchHomeData = async () => {
    setLoading(true);
    try {
      const fetchedData = await getAll();
      setHomeCategories(fetchedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching categories:", error);
    }
  };

  const fetchHomeSettings = async () => {
    try {
      const fetchedData = await getWebSettings();
      setWebSettings(fetchedData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Pass live_tv to children
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { live_tv: webSettings.live_tv })
  );

  return (
    <div>
      <Header socials={webSettings.social} />
      <main>{childrenWithProps}</main>
      <Footer
        categories={categories}
        loading={loading}
        socials={webSettings.social}
      />
    </div>
  );
}

export default MainLayout;
