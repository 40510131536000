import React from "react";

function Ads({ image, link, height, width }) {
  return (
    <div className="container-fluid pb-1">
      <div className="container py-1 pb-0 text-center">
        <a href={link ? link : false} target="_new">
          <img
            src={image}
            alt=""
            style={{
              height: height ? height : "75px",
              width: width ? width : "auto",
            }}
          />
        </a>
      </div>
    </div>
  );
}

export default Ads;
