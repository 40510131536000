import React, { useEffect, useState } from "react";
import { getNewsRoom } from "../services/articles";
import { ClockLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";

function NewsRoom() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchNewsRoomData();
  }, []);

  const fetchNewsRoomData = async () => {
    setLoading(true);
    try {
      const fetchedData = await getNewsRoom();
      setNewsData(fetchedData.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching home data:", error);
    }
  };

  return (
    <>
      <div className="container-fluid py-5">
        <div className="container my-5">
          {!loading ? (
            <>
              <div>
                {Object.keys(newsData).map((category) => (
                  <>
                    {newsData[category]?.articles?.length > 0 && (
                      <div key={category}>
                        <h2>{newsData[category]?.name}</h2>
                        <div className="row">
                          {newsData[category]?.articles.map(
                            (article, index) => (
                              <div className="col-md-3 col-sm-6 mb-3">
                                <div
                                  className="card"
                                  onClick={() => {
                                    navigate(`/news/${article?.slug}`);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={article?.image?.url}
                                    className="card-img-top"
                                    alt="..."
                                  />
                                  <div className="card-body">
                                    <h5 className="card-title ml">
                                      {article?.title}
                                    </h5>
                                    <div className="d-flex justify-content-between align-items-center text-muted small">
                                      <div>
                                        <i className="fa fa-calendar" />
                                        &nbsp;{article?.published_date}
                                      </div>
                                      <div>
                                        <i className="fa fa-user-circle" />
                                        &nbsp;{article?.user?.name}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                          <Link
                            to={`/category/${category}`}
                            className="btn btn-outline-primary d-flex align-items-center"
                          >
                            More&nbsp;
                            <i className="fa fa-chevron-right" />
                          </Link>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </>
          ) : (
            <ClockLoader className="m-auto" color="#84bf42" />
          )}
        </div>
      </div>
    </>
  );
}

export default NewsRoom;
