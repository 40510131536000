import React, { useEffect, useState } from "react";
import HtmlContent from "../HtmlContent";
import { Link } from "react-router-dom";
import SocialIcons from "../SocialIcons";
import Ads from "../Ads";
function HomeTop({ data, live_tv, ad, epaper_image }) {
  const [mainData, setMainData] = useState([]);
  const [listData, setListData] = useState([]);
  const [endData, setEndData] = useState([]);
  useEffect(() => {
    setMainData(data.featured);
    setListData(data.list);
    setEndData(data.end);
  }, [data.end, data.featured, data.list]);
  return (
    <>
      <div className="container-fluid populer-news pb-1">
        <div className="container py-5 pb-0">
          <div className="tab-class mb-1">
            <div className="row g-4">
              <div className="col-lg-7 col-xl-8">
                <div className="tab-content mb-1">
                  <div id="tab-1" className="tab-pane fade show p-0 active">
                    <div className="container">
                      <div className="row g-4">
                        <div className="col-lg-8">
                          <div className="row">
                            <div className="col-5 col-lg-12">
                              <div className="position-relative rounded-0 overflow-hidden">
                                <Link Link to={`news/${mainData?.slug}`}>
                                  <img
                                    src={mainData?.image?.url}
                                    className="img-zoomin img-fluid rounded-0 -0 w-100"
                                    alt=""
                                  />
                                </Link>
                                <div
                                  className="position-absolute text-white px-4 py-2 bg-primary rounded d-none d-sm-none d-md-block"
                                  style={{ top: "20px", right: "20px" }}
                                >
                                  <Link
                                    Link
                                    to={`category/${mainData?.category?.slug}`}
                                    className="text-white"
                                  >
                                    {mainData?.category?.name}
                                  </Link>
                                </div>
                              </div>
                              <SocialIcons
                                url={
                                  process.env.REACT_APP_ASSET_URL +
                                  `news/${mainData?.slug}`
                                }
                                className="d-none d-sm-none d-md-block"
                              />
                            </div>
                            <div className="col-7 col-lg-12">
                              <Link Link to={`news/${mainData?.slug}`}>
                                <h3
                                  className="h3 ml"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {mainData?.title}
                                </h3>
                              </Link>
                            </div>
                          </div>
                          <div className="my-4 h5 ml d-none d-sm-none d-md-block">
                            <Link Link to={`news/${mainData?.slug}`}>
                              <HtmlContent data={mainData?.content} />
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="row g-4">
                            {listData &&
                              listData.map((article) => (
                                <div className="col-12">
                                  <div className="row g-4 align-items-center">
                                    <div className="col-5 mt-0">
                                      <div className="overflow-hidden rounded-0">
                                        <Link to={`news/${article?.slug}`}>
                                          <img
                                            src={article?.image?.url}
                                            className="img-zoomin img-fluid rounded-0  w-100"
                                            alt=""
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="col-7 mt-0">
                                      <div className="features-content d-flex flex-column">
                                        <p className="text-uppercase mb-2">
                                          <Link
                                            Link
                                            to={`category/${article?.category?.slug}`}
                                            className="text-muted"
                                          >
                                            {article?.category?.name}
                                          </Link>
                                        </p>
                                        <Link
                                          to={`news/${article?.slug}`}
                                          className="h6 ml"
                                        >
                                          {article?.title}
                                        </Link>
                                        {/* <small className="text-body d-block"><i className="fas fa-calendar-alt me-1"></i> Dec 9, 2024</small>*/}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-xl-4">
                <div className="row g-4">
                  <div className="col-12">
                    <div className="p-3 rounded border">
                      <div className="row g-4">
                        <div className="col-lg-12 my-0 py-0">
                          <div className="row">
                            <div className="col-lg-6">
                              {/* <h4 className="mb-1">E-Paper</h4>*/}
                              <div className="row">
                                <div className="col epaper rounded-1">
                                  <Link
                                    title="Epaper"
                                    className=""
                                    to={epaper_image ? "/epaper" : false}
                                  >
                                    <img
                                      src={
                                        epaper_image
                                          ? epaper_image
                                          : process.env.REACT_APP_ASSET_URL +
                                            "assets/img/epaper.jpg"
                                      }
                                      alt=""
                                      className="border border-dark rounded-3 p-2"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 mt-3">
                              <div className="col-12">
                                <div className="row g-4 align-items-center features-item">
                                  <div className="col-12">
                                    <div className="rounded-circle position-relative">
                                      <div className="overflow-hidden rounded-0-circle">
                                        <Link to={`news/${endData?.slug}`}>
                                          <img
                                            src={endData?.image?.url}
                                            className="img-zoomin img-fluid rounded-0 -circle w-100"
                                            alt=""
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="features-content d-flex flex-column">
                                      <p className="text-uppercase mb-2">
                                        <Link
                                          Link
                                          to={`category/${endData?.category?.slug}`}
                                          className="text-muted"
                                        >
                                          {endData?.category?.name}
                                        </Link>
                                      </p>
                                      <Link
                                        to={`news/${endData?.slug}`}
                                        className="h6 ml"
                                      >
                                        {endData?.title}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {ad?.image && (
                          <>
                            <div className="col-lg-12">
                              <Ads
                                image={ad.image}
                                link={ad.link}
                                width={"100%"}
                                height={"auto"}
                              />
                            </div>
                          </>
                        )}
                        <div className="col-lg-12">
                          <div className="position-relative banner-2">
                            <div className="video-container">
                              <iframe
                                width="424"
                                height="238"
                                src={live_tv?.media_url}
                                title="LIVE News"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeTop;
