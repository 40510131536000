import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // For making API requests
import { registerUser } from "../services/authService";

function Register() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validate = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required";
    }
    if (!formData.password.trim()) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password needs to be 6 characters or more";
    }

    return errors;
  };

  const createUser = async (e) => {
    e.preventDefault();
    setErrors(validate());
    setIsSubmitting(true);
    if (Object.keys(errors).length === 0) {
      try {
        const response = await registerUser(formData);
        console.log(response);
        if (response?.access_token) {
          setIsSuccess(true);
          setTimeout(() => {
            navigate("/login");
          }, 500);
        }
        setIsSubmitting(false);
      } catch (error) {
        console.error("There was an error registering the user!", error);
        // Assuming the error response is in the format { "field_name": ["error message"] }
        if (error.response && error.response.data) {
          setErrors(error.response.data);
        } else {
          // Handle other types of errors (network issues, etc.)
          setErrors({ general: "Something went wrong. Please try again." });
        }
        setIsSubmitting(false);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <div className="container-fluid populer-news mt-3 mb-5">
      <div className="container py-3">
        <div className="row">
          <div className="col-md-4 col-sm-12 m-auto">
            <div className="m-auto">
              <div className="card p-2 bg-primary">
                <div className="card-header">
                  <h3>Register</h3>
                </div>
                <div className="card-body bg-white">
                  <form onSubmit={createUser}>
                    <div className="input-group form-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text p-3">
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.name && (
                      <div className="text-danger">{errors.name}</div>
                    )}
                    <div className="input-group form-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text p-3">
                          <i className="fas fa-envelope"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                    <div className="input-group form-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text p-3">
                          <i className="fas fa-mobile"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.phone && (
                      <div className="text-danger">{errors.phone}</div>
                    )}
                    <div className="input-group form-group mt-1">
                      <div className="input-group-prepend">
                        <span className="input-group-text p-3">
                          <i className="fas fa-key"></i>
                        </span>
                      </div>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.password && (
                      <div className="text-danger ">{errors.password}</div>
                    )}
                    {isSuccess && (
                      <div className="text-success border border-success p-3 rounded-2">
                        Registration completed
                      </div>
                    )}
                    <div className="form-group">
                      <input
                        type="submit"
                        value="Register"
                        className="btn btn-primary btn-block mt-3 m-auto"
                        style={{ width: "100%" }}
                        disabled={isSubmitting}
                      />
                    </div>
                  </form>
                </div>
                <div className="text-center py-3">
                  Click here to{" "}
                  <a
                    href={true}
                    className="text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
