import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getArticleBySlug } from "../services/articles";
import { ClockLoader } from "react-spinners";
import HtmlContent from "../components/HtmlContent";
import SocialIcons from "../components/SocialIcons";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";

function NewsView() {
  const location = useLocation();
  const currentUrl =
    window.location.origin + location.pathname + location.search;

  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([]);
  const [relatedNews, setRelatedNews] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const fetchNews = async () => {
    setLoading(true);
    try {
      const article = await getArticleBySlug(slug);
      setNews(article);
      setRelatedNews(article.related_news);
      setLoading(false);
    } catch (error) {
      console.error("Article not found:", error);
      setLoading(false);
    }
  };

  const wrapNumbersInSpans = (text) => {
    if (typeof text !== "string") return "";
    return text.replace(
      /\d+/g,
      (match) => `<span class="number">${match}</span>`
    );
  };

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  const stripHtml = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  // Construct JSON-LD structured data for the article
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "News",
    headline: news.title,
    image: news.image?.url,
    author: {
      "@type": "Person",
      name: news?.user?.name,
    },
    datePublished: news.published_date,
    publisher: {
      "@type": "Organization",
      name: "Keralanadham",
      logo: {
        "@type": "ImageObject",
        url: "./logo192.png",
      },
    },
    description: stripHtml(news.content).substring(0, 200),
    url: currentUrl,
  };

  return (
    <>
      {loading && (
        <div className="py-5 my-5">
          <ClockLoader className="m-auto" color="#84bf42" />
        </div>
      )}
      {!loading && (
        <>
          <HelmetProvider>
            <Helmet>
              <title>{news.title}</title>
              <link rel="icon" href={news.image?.url} />
              <link rel="apple-touch-icon" href={news.image?.url} />
              <meta name="description" content={news.title} />
              <meta property="og:title" content={news.title} />
              <meta property="og:description" content={news.title} />
              <meta property="og:image" content={news.image?.url} />
              <meta property="og:url" content={currentUrl} />
              <meta property="og:type" content="article" />
              <meta property="og:site_name" content="Keralanadham" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={news.title} />
              <meta
                name="twitter:description"
                content={stripHtml(news.content).substring(0, 200)}
              />
              <meta name="twitter:image" content={news.image?.url} />
              {/* JSON-LD structured data */}
              <script type="application/ld+json">
                {JSON.stringify(jsonLdData)}
              </script>
            </Helmet>

            <div className="container-fluid py-1">
              <div className="container py-1">
                <div className="row">
                  <div className="col-lg-8 p-5">
                    <h1
                      className="ml"
                      dangerouslySetInnerHTML={createMarkup(
                        wrapNumbersInSpans(news?.title)
                      )}
                    />
                    <div>
                      <p>{news?.category?.name}</p>
                      <div className="p-1">
                        <img
                          className="img-fluid rounded"
                          src={news?.image?.url}
                          alt={news.title}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <i className="fa fa-calendar" />
                        &nbsp; {news?.published_date}
                        &nbsp;|&nbsp;
                        <i className="fa fa-user-circle" />
                        &nbsp; {news?.user?.name}
                      </div>
                      <SocialIcons
                        url={
                          process.env.REACT_APP_ASSET_URL + `news/${news?.slug}`
                        }
                      />
                    </div>
                    <p className="text-dark ml" style={{ fontSize: "1.2rem" }}>
                      <HtmlContent data={wrapNumbersInSpans(news.content)} />
                    </p>
                  </div>
                  {relatedNews.length > 0 && (
                    <div className="col-lg-4 mt-5">
                      <h5>Related News</h5>
                      {relatedNews &&
                        relatedNews.map((article) => (
                          <div className="col-12 mt-5" key={article.slug}>
                            <div className="row g-4 align-items-center">
                              <div className="col-5 mt-0">
                                <div className="overflow-hidden rounded-0">
                                  <img
                                    src={article?.image?.url}
                                    className="img-zoomin img-fluid rounded-0 w-100"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-7 mt-0">
                                <div className="features-content d-flex flex-column">
                                  <p className="text-uppercase mb-2">
                                    {article?.category?.name}
                                  </p>
                                  <Link
                                    to={`/news/${article?.slug}`}
                                    className="h6 ml"
                                  >
                                    {article?.title}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </HelmetProvider>
        </>
      )}
    </>
  );
}

export default NewsView;
