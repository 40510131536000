import axios from "axios";

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Add a request interceptor to set the CSRF token
instance.interceptors.request.use(
  function (config) {
    const token = document.cookie
      .split(";")
      .find((cookie) => cookie.trim().startsWith("XSRF-TOKEN="));
    const auth_token = localStorage.getItem("auth");
    if (token) {
      config.headers["X-XSRF-TOKEN"] = decodeURIComponent(token.split("=")[1]);
    }
    if (auth_token) {
      config.headers["Authorization"] = `Bearer ${auth_token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
