import axios from "./axiosConfig";
import Cookies from "js-cookie";

const API_URL = "/api"; // Adjust if necessary

export const authenticateUser = async (payload) => {
  try {
    await axios.get(`${API_URL}/csrf-cookie`);
    const response = await axios.post(`${API_URL}/login`, payload);
    if (response.data.token) {
      localStorage.setItem("auth", response.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      return response.data;
    }
    return false;
  } catch (error) {
    console.error("Authentication error:", error);
    throw error;
  }
};

export const registerUser = async (payload) => {
  try {
    await axios.get(`${API_URL}/csrf-cookie`);
    const response = await axios.post(`${API_URL}/register`, payload);
    if (response?.data) {
      //localStorage.setItem("auth", response.data.token);
      //localStorage.setItem("user", JSON.stringify(response.data.user));
      return response?.data;
    }
    return false;
  } catch (error) {
    console.error("Authentication error:", error);
    throw error;
  }
};

export const isAuthenticated = () => {
  return !!localStorage.getItem("auth");
};

export const logout = async () => {
  try {
    await axios.get(`${API_URL}/csrf-cookie`);
    await axios.post(`${API_URL}/logout`, {}, { withCredentials: true });
    Cookies.remove("XSRF-TOKEN");
    localStorage.removeItem("auth");
    localStorage.removeItem("user");
    return { success: true };
  } catch (error) {
    console.error("Logout error:", error);
    return { success: false, error };
  }
};

export const getUser = () => {
  const auth = localStorage.getItem("auth");
  return auth ? JSON.parse(auth) : null;
};
