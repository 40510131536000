// src/components/Stories.js
import React, { useEffect, useState } from "react";
import Stories from "react-insta-stories";
import StoryLayout from "../layouts/StoryLayout";
import { getAll } from "../services/articles";

const StoriesComponent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [storiesData, setStoriesData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const fetched = await getAll({ category: 7 });
      const fetchedData = fetched.data;
      let data = fetchedData.map((item) => {
        return {
          image: item?.image?.url,
          caption: item?.title,
        };
      });
      setStoriesData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching home data:", error);
    }
  };

  const storyContent = storiesData.map((story) => ({
    content: () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          backgroundColor: "#333",
        }}
      >
        <img
          src={story.image}
          alt="story"
          style={{ maxHeight: "80%", maxWidth: "80%", marginBottom: "20px" }}
        />
        <p style={{ color: "#fff", fontSize: "16px", textAlign: "center" }}>
          {story.caption}
        </p>
      </div>
    ),
  }));

  const onStoryChange = (index) => {
    setCurrentIndex(index);
    console.log(`Current story index: ${index}`);
  };

  return (
    <>
      {!loading && storiesData.length > 0 && (
        <StoryLayout background={storiesData[currentIndex]?.image}>
          <Stories
            stories={storyContent}
            defaultInterval={2000}
            width={432}
            height={768}
            //onStoryEnd={onStoryChange}
            onStoryStart={onStoryChange}
          />
        </StoryLayout>
      )}
    </>
  );
};

export default StoriesComponent;
