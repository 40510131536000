import React, { useEffect, useState } from "react";
import HtmlContent from "../HtmlContent";
import { useNavigate } from "react-router-dom";
import SocialIcons from "../SocialIcons";

function HomeMain({ data }) {
  const navigate = useNavigate();
  const [featured1, setFeatured1] = useState([]);
  const [featured2, setFeatured2] = useState([]);
  const [listData, setListData] = useState([]);
  const [topStory, setTopStory] = useState([]);
  useEffect(() => {
    setFeatured1(data.featured_1);
    setFeatured2(data.featured_2);
    setListData(data.list);
    setTopStory(data.top_story);
  }, [data.featured_1, data.featured_2, data.list, data.top_story]);

  return (
    <>
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="row g-4">
            <div className="col-lg-7 col-xl-8 mt-0">
              <div className="position-relative overflow-hidden rounded-0">
                <img
                  src={featured1?.image?.url}
                  className="img-fluid rounded-0  img-zoomin w-100"
                  alt=""
                  onClick={() => {
                    navigate(`/news/${featured1?.slug}`);
                  }}
                  style={{ cursor: "pointer" }}
                />
                <div
                  className="d-flex justify-content-between px-4 position-absolute flex-wrap align-items-center"
                  style={{ bottom: "10px", left: 0, width: "100%" }}
                >
                  <div>
                    <a href={true} className="text-dark me-3 link-hover">
                      <i className="fa fa-calendar"></i>{" "}
                      {featured1?.published_date}
                    </a>

                    <a href={true} className="text-dark link-hover">
                      <i className="fa fa-user-circle"></i>{" "}
                      {featured1?.user?.name}
                    </a>
                  </div>
                  <SocialIcons
                    url={
                      process.env.REACT_APP_ASSET_URL +
                      `news/${featured1?.slug}`
                    }
                  />
                </div>
              </div>
              <div className="border-bottom py-3">
                <a
                  href={true}
                  className="display-5 text-dark mb-0 link-hover ml"
                  onClick={() => {
                    navigate(`/news/${featured1?.slug}`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {featured1?.title}
                </a>
              </div>
              <p className="mt-3 mb-4 ml" style={{ fontSize: "1.2rem" }}>
                <HtmlContent data={featured1?.content} />
              </p>
              <div className="bg-light p-4 rounded">
                <div className="news-2">
                  <h3 className="mb-4">Top Story</h3>
                </div>
                <div className="row g-4 align-items-center">
                  <div className="col-md-6">
                    <div className="rounded-0 overflow-hidden">
                      <img
                        src={topStory?.image?.url}
                        className="img-fluid rounded-0  img-zoomin w-100"
                        alt=""
                        onClick={() => {
                          navigate(`/news/${topStory?.slug}`);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <a
                        href={true}
                        className="h3 ml"
                        onClick={() => {
                          navigate(`/news/${topStory?.slug}`);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {topStory?.title}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xl-4">
              <div className="bg-light rounded p-4 pt-0">
                <div className="row g-4">
                  <div className="col-12">
                    <div className="rounded-0 overflow-hidden">
                      <img
                        src={featured2?.image?.url}
                        className="img-fluid rounded-0  img-zoomin w-100"
                        alt=""
                        onClick={() => {
                          navigate(`/news/${featured2?.slug}`);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <a
                        href={true}
                        className="h4 mb-2 ml"
                        onClick={() => {
                          navigate(`/news/${featured2?.slug}`);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {featured2?.title}
                      </a>
                    </div>
                  </div>

                  {listData &&
                    listData.map((article) => (
                      <div className="col-12">
                        <div className="row g-4 align-items-center">
                          <div className="col-5">
                            <div className="overflow-hidden rounded-0">
                              <img
                                src={article?.image?.url}
                                className="img-zoomin img-fluid rounded-0  w-100"
                                alt=""
                                onClick={() => {
                                  navigate(`/news/${article?.slug}`);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>
                          <div className="col-7">
                            <div className="features-content d-flex flex-column">
                              <a
                                href={true}
                                className="h6 ml"
                                onClick={() => {
                                  navigate(`/news/${article?.slug}`);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {article?.title}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeMain;
