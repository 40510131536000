import React from "react";

function WebStories({ data }) {
  return (
    <>
      <div className="container-fluid py-1 bg-dark">
        <div className="container py-1">
          <div className="mt-1 lifestyle">
            <div className="border-bottom mb-4">
              <h1 className="mb-4 text-center text-light">WEB STORIES</h1>
            </div>
            <div className="row g-4">
              {data &&
                data.map((article) => (
                  <div className="col-lg-4">
                    <div className="lifestyle-item rounded">
                      <img
                        src={article?.image?.url}
                        className="img-fluid w-100 rounded"
                        alt=""
                      />
                      <div className="lifestyle-content">
                        <div className="mt-auto">
                          <a
                            href="/stories"
                            className="h4 text-white link-hover ml"
                            target="_blank"
                          >
                            {article?.title}
                          </a>
                          <div className="d-flex justify-content-between mt-4">
                            {/* <a href={true} className="small text-white link-hover">
                          By Willium Smith
                        </a> */}
                            <small className="text-white d-block">
                              <i className="fas fa-calendar-alt me-1"></i>
                              {article?.published_date}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WebStories;
