import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

function SocialIcons({ url, className }) {
  return (
    <>
      <div className={`mt-1 d-flex ${className}`}>
        {/* <EmailShareButton url={url}>
          <EmailIcon size={25} round={true} />
        </EmailShareButton>
        &nbsp; */}
        <FacebookShareButton url={url}>
          <FacebookIcon size={25} round={true} />
        </FacebookShareButton>
        &nbsp;
        <LinkedinShareButton url={url}>
          <LinkedinIcon size={25} round={true} />
        </LinkedinShareButton>
        &nbsp;
        <TelegramShareButton url={url}>
          <TelegramIcon size={25} round={true} />
        </TelegramShareButton>
        &nbsp;
        <TwitterShareButton url={url}>
          <TwitterIcon size={25} round={true} />
        </TwitterShareButton>
        &nbsp;
        <WhatsappShareButton url={url}>
          <WhatsappIcon size={25} round={true} />
        </WhatsappShareButton>
      </div>
    </>
  );
}

export default SocialIcons;
