import React from "react";

const HtmlContent = ({ data }) => {
  return (
    <div>
      <div className="my-4" dangerouslySetInnerHTML={{ __html: data }} />
    </div>
  );
};

export default HtmlContent;
