import React, { useState, useRef, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import ImageMapper from "react-image-mapper";

const EpaperMain = ({ imageUrl, areas = [] }) => {
  const [popupImage, setPopupImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [mapAreas, setMapAreas] = useState([]);
  const [mapperSize, setMapperSize] = useState({ width: 700, height: 0 });
  const containerRef = useRef(null);
  const imageRef = useRef(null);

  const updateMapperSize = useCallback(() => {
    if (containerRef.current && imageRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const aspectRatio =
        imageRef.current.naturalHeight / imageRef.current.naturalWidth;
      setMapperSize({
        width: containerWidth,
        height: containerWidth * aspectRatio,
      });
    }
  }, []);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      imageRef.current = img;
      updateMapperSize();
    };
    img.src = imageUrl;
  }, [imageUrl, updateMapperSize]);

  useEffect(() => {
    window.addEventListener("resize", updateMapperSize);
    return () => window.removeEventListener("resize", updateMapperSize);
  }, [updateMapperSize]);

  useEffect(() => {
    const scaleFactor = mapperSize.width / 700;
    const scaledAreas = areas.map((area) => ({
      ...area,
      coords: [
        area.x * scaleFactor,
        area.y * scaleFactor,
        (area.x + area.width) * scaleFactor,
        (area.y + area.height) * scaleFactor,
      ],
    }));
    setMapAreas(scaledAreas);
  }, [areas, mapperSize.width]);

  const handleAreaClick = (area) => {
    setPopupImage(area.url);
    setShowModal(true);
  };

  const map = {
    name: "my-map",
    areas: mapAreas.map((area) => ({
      ...area,
      shape: "rect",
      href: area.linked_url,
    })),
  };

  return (
    <div ref={containerRef}>
      <ImageMapper
        src={imageUrl}
        map={map}
        width={mapperSize.width}
        height={mapperSize.height}
        onClick={(area) => handleAreaClick(area)}
        className="m-auto"
        style={{ margin: "auto" }}
        fillColor="rgba(0, 0, 0, 0.2)"
      />

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img
            src={popupImage}
            alt="Linked Content"
            style={{ width: "100%", height: "auto" }}
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div>
            <a
              id="download_clip"
              type="button"
              className="btn btn-outline-dark btn-sm rounded-pill waves-effect mx-1"
              target="_blank"
              download=""
              href={popupImage}
              rel="noreferrer"
            >
              <i className="fa fa-download"></i>
            </a>
            <a
              id="whatsapp_clip"
              type="button"
              className="btn btn-outline-success btn-sm rounded-pill waves-effect mx-1"
              target="_blank"
              data-action="share/whatsapp/share"
              href={`https://wa.me/?text=${popupImage}`}
              rel="noreferrer"
            >
              <i className="fab fa-whatsapp" aria-hidden="true"></i>
            </a>
            <button
              className="btn btn-outline-danger btn-sm rounded-5"
              onClick={() => {
                setShowModal(false);
              }}
            >
              <i className="fa fa-times-circle" aria-hidden="true"></i>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EpaperMain;
