import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authenticateUser } from "../services/authService";

function Login({ setAuth }) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");
      const response = await authenticateUser({ username, password });
      if (response && response.token) {
        setError("");
        setLoading(false);
        window.location.href = "/";
      } else {
        setLoading(false);
        setError("Invalid username or password");
      }
    } catch (error) {
      setLoading(false);
      setError("Failed to authenticate");
    }
  };
  return (
    <>
      <div className="container-fluid populer-news mt-3 mb-5">
        <div className="container py-3">
          <div className="row">
            <div className="col-md-4 col-sm-12 m-auto">
              <div className="m-auto">
                <div className="card p-2 bg-primary">
                  <div className="card-header">
                    <h3>Sign In</h3>
                  </div>
                  <div className="card-body bg-white">
                    {error && <div className="alert alert-danger">{error}</div>}
                    <form onSubmit={handleSubmit}>
                      <div className="input-group form-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text p-3">
                            <i className="fas fa-user"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="username"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      <div className="input-group form-group mt-1">
                        <div className="input-group-prepend">
                          <span className="input-group-text p-3">
                            <i className="fas fa-key"></i>
                          </span>
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      {/* <div className="row align-items-center remember">
                    <input type="checkbox" />
                    Remember Me
                  </div> */}
                      <div className="form-group">
                        <button
                          type="submit"
                          value="Login"
                          className="btn btn-primary btn-block mt-3 m-auto"
                          style={{ width: "100%" }}
                        >
                          {loading && (
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          {!loading && <>Login</>}
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-center links">
                      Don't have an account?&nbsp;
                      <a
                        href={true}
                        className="text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/register");
                        }}
                      >
                        Sign Up
                      </a>
                    </div>
                    <div className="d-flex justify-content-center">
                      <a href={true}>Forgot your password?</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
